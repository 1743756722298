import Prism from "prismjs";
import { useEffect } from "react";

import "prismjs/themes/prism-okaidia.css";
import "prismjs/plugins/line-numbers/prism-line-numbers.js";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import { join } from "lodash";

interface Props {
  className?: string;
  code: string;
  lang?: string;
}

export function Code(props: Props) {
  useEffect(() => {
    Prism.highlightAll();
  }, [props.code, props.lang]);

  return (
    <pre className={join(["line-numbers", props.className], " ")}>
      <code
        className={join(
          ["language-" + props.lang, "!text-xs inline-block max-w-full"],
          " "
        )}
      >
        {props.code}
      </code>
    </pre>
  );
}
