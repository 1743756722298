import { DateTime, Duration } from "luxon";
import { Activity, Calendar, Clock } from "react-feather";
import { statusTextColor } from "../../api/services/Job";
import { Status } from "../../domain/state";
import { durationStr } from "../../utils";

interface Props {
  duration: Duration;
  datetime: DateTime | undefined;
  status: Status;
}

export function Stats(props: Props) {
  const stats = [
    {
      name: "Average Duration",
      stat: durationStr(props.duration),
      icon: Clock,
      className: "",
    },
    {
      name: "Last Build",
      stat: props.datetime?.toRelative(),
      icon: Calendar,
      className: "",
    },
    {
      name: "Last Status",
      stat: Status[props.status],
      icon: Activity,
      className: statusTextColor(props.status),
    },
  ];

  return (
    <div className="hidden md:block">
      <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map((item, i) => (
          <div className="flex items-center px-5 py-5 sm:p-6" key={i}>
            <div className="flex-shrink-0">
              <item.icon className="h-6 w-6 text-gray-400" />
            </div>
            <div key={item.name} className="ml-4">
              <dt className="text-base text-gray-400 font-medium">
                {item.name}
              </dt>
              <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                <div
                  className={
                    "flex items-baseline text-2xl font-semibold " +
                    item.className
                  }
                >
                  {item.stat}
                </div>
              </dd>
            </div>
          </div>
        ))}
      </dl>
    </div>
  );
}
