import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { durationStr } from "../../utils";

interface Props {
  start: DateTime;
  end?: DateTime;
  running?: boolean;
}

export function Duration(props: Props) {
  const [now, setNow] = useState(DateTime.now());

  useEffect(() => {
    if (props.running) {
      let interval = setInterval(() => {
        setNow(DateTime.now());
      }, 1000);

      return () => clearInterval(interval);
    } else if (props.end) {
      setNow(props.end);
    }
  }, [props.start, props.running, props.end]);

  return (
    <>{props.running || props.end ? durationStr(now.diff(props.start)) : "-"}</>
  );
}
