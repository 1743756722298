import axios from "axios";
import { DateTime } from "luxon";

export const api = axios.create({
  baseURL: "/api/v1",
  headers: {
    "Content-type": "application/json",
  },
});

api.interceptors.response.use((response) => {
  const data = response.data;
  if (data && typeof data === "object") {
    convertDates(data);
  }
  return response;
});

export const getData = (response: any) => {
  return response.data["data"];
};

export interface Resp<DataType> {
  code: number;
  message: string;
  data: DataType;
}

const convertDates = (data: any) => {
  const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+[+-]\d{2}:\d{2}/;

  if (data && typeof data === "object") {
    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (typeof value === "string" && dateRegex.test(value)) {
        const dt = DateTime.fromISO(value);
        const offset = dt.toFormat("ZZ");
        data[key] = dt.setZone(`UTC${offset}`);
      } else if (typeof value === "object") {
        convertDates(value);
      }
    });
  }
};
