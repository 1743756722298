import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigation } from "./navigation";
import { atomWithStorage } from "jotai/utils";
import { useParams } from "react-router-dom";
import { useParamsNumber } from "../utils";

const currentSrcAtom = atomWithStorage<number>("currentSrc", -1);
export const useCurrentSrc = () => {
  return useAtom(currentSrcAtom);
};

const currentRepoAtom = atom<number>(-1);

export const useCurrentRepo = () => {
  return useAtom(currentRepoAtom);
};

export const useUpdateRepo = (repoId: number) => {
  const [, setCurRepo] = useCurrentRepo();
  const [nav, setNav] = useNavigation();
  useEffect(() => {
    setCurRepo(repoId);
    setNav(nav.withRepo(repoId));
  }, [repoId]); // eslint-disable-line
};

export const useRepoId = () => {
  const repoId = useParamsNumber("repoId");
  useUpdateRepo(repoId ?? -1);
  return repoId ?? -1;
};
