import { Globe, Plus, User, Server, ChevronRight } from "react-feather";
import { Button } from "../../components/button/Button";
import {
  sourceLogo,
  Type,
  useAddSource,
  useSources,
} from "../../api/services/Source";
import { useEffect, useState } from "react";
import { Modal } from "../../components/modal/Modal";
import { inputChange } from "../../utils";
import { Link } from "react-router-dom";
import { useHeader } from "../header/Header";

export function SourceList() {
  const { data: sources } = useSources();
  const [adOpen, setAdOpen] = useState(false);

  const setHeader = useHeader();
  useEffect(() => {
    setHeader([{ name: "Source" }]);
  }, []);

  return (
    <>
      <div className="flex m-3 rounded-md bg-white border border-gray-200 p-4">
        <span className="flex-1"></span>
        <Button
          text="Add"
          icon={Plus}
          onClick={() => {
            setAdOpen(true);
          }}
        />
      </div>
      <div className="bg-white min-w-full border-t border-b border-gray-200">
        <ul className="divide-y divide-gray-200">
          {sources?.map((src, idx) => (
            <li key={idx}>
              <Link to={`/source/${src.id}`}>
                <div className="flex items-center p-4 hover:bg-gray-50">
                  <img
                    alt="type-img"
                    className="rounded-full h-12 w-12"
                    src={sourceLogo(src.type)}
                  />

                  <span className="flex flex-col flex-1 ml-4 gap-2">
                    <span className="text-sm font-medium">
                      <span className="text-indigo-600">{src.name}</span>
                      <span className="text-gray-500 ml-1">@{src.type}</span>
                      <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800 ml-3">
                        Owner
                      </span>
                    </span>
                    <span className="text-sm font-medium flex gap-6">
                      <span className="text-gray-500 flex items-center">
                        <Globe className="w-4 h-4 mr-1" />
                        {src.url}
                      </span>
                      <span className="text-gray-500 flex items-center">
                        <Server className="w-4 h-4 mr-1" />
                        12 repos
                      </span>
                      <span className="text-gray-500 flex items-center">
                        <User className="w-4 h-4 mr-1" />
                        chenao
                      </span>
                    </span>
                  </span>

                  <div className="isolate flex -space-x-2 overflow-hidden">
                    <img
                      className="relative z-30 inline-block h-8 w-8 rounded-full ring-2 ring-white"
                      src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <img
                      className="relative z-20 inline-block h-8 w-8 rounded-full ring-2 ring-white"
                      src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <img
                      className="relative z-10 inline-block h-8 w-8 rounded-full ring-2 ring-white"
                      src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                      alt=""
                    />
                    <img
                      className="relative z-0 inline-block h-8 w-8 rounded-full ring-2 ring-white"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>

                  <ChevronRight className="ml-2 h-5 w-5 text-gray-400" />
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <AddDialog
        open={adOpen}
        onClose={() => {
          setAdOpen(false);
        }}
      />
    </>
  );
}

interface adProps {
  open: boolean;
  onClose: () => void;
}

function AddDialog(props: adProps) {
  const addSrc = useAddSource();
  const [src, setSrc] = useState({
    name: "",
    type: Type.Gitea,
    url: "",
    username: "",
    accessToken: "",
  });

  const handleChange = inputChange(src, setSrc);

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <h3 className="text-lg font-medium leading-6 text-gray-900 mb-6">
        Add a new source
      </h3>

      <form className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="name"
              onChange={handleChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Type
          </label>
          <div className="mt-1">
            <select
              name="type"
              onChange={handleChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
            >
              <option value="gitea">Gitea</option>
              <option value="gitlab">Gitlab</option>
            </select>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600">URL</label>
          <div className="mt-1">
            <input
              type="text"
              name="url"
              onChange={handleChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Username
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="username"
              onChange={handleChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Access Token
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="accessToken"
              onChange={handleChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
      </form>

      <div className="flex flex-row-reverse mt-5">
        <Button
          text="Add"
          onClick={() => {
            addSrc.mutate(src, {
              onSuccess: () => {
                props.onClose();
              },
            });

            console.log(src);
          }}
          className="bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500"
        />
        <Button text="Cancel" onClick={props.onClose} className="px-5 mr-2" />
      </div>
    </Modal>
  );
}
