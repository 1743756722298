import { Config, ConfigIO, useUpdateConfig } from "../../api/services/Config";
import { Dialog } from "./Dialog";

interface edProps {
  conf?: Config;
  open: boolean;
  onClose: () => void;
}

export function EditDialog(props: edProps) {
  const updateConf = useUpdateConfig();

  if (props.conf === undefined) {
    return <></>;
  }

  return (
    <Dialog
      open={props.open}
      conf={props.conf!!}
      confirmText="Update"
      onClose={props.onClose}
      onConfirm={(conf: ConfigIO) =>
        updateConf.mutate(
          { id: props.conf?.id ?? -1, conf },
          {
            onSuccess: props.onClose,
          }
        )
      }
    />
  );
}
