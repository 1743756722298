import { join } from "lodash";
import { DateTime, Duration } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Job, statusBgColor, useRerunJob } from "../../api/services/Job";
import { useJobs, useRepoDetail } from "../../api/services/Repo";
import { SimplePagination } from "../../components/pagination/Simple";
import { Commit, Ref } from "../../components/repo";
import { Duration as DurationRealtime } from "../../components/utils/Duration";
import { Status } from "../../domain/state";
import { useRepoId, useUpdateRepo } from "../../state";
import { useHeader } from "../header/Header";
import { Stats } from "../repo/Stats";

export function JobList() {
  const perPage = 20;
  const repoId = useRepoId();
  useUpdateRepo(repoId);

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data: jobs } = useJobs(repoId, { page, perPage });
  const { data: repo } = useRepoDetail(repoId);
  const rerunJob = useRerunJob();

  const stats = jobStats(jobs?.list ?? []);

  const setHeader = useHeader();
  useEffect(() => {
    if (repo) {
      setHeader([
        { name: "Repository", href: "/" },
        { name: `${repo.owner}/${repo.name}` },
      ]);
    }
  }, [repo, setHeader]);

  return (
    <>
      <div className="p-4">
        <Stats
          duration={stats.duration}
          datetime={stats.datetime}
          status={stats.status}
        />
      </div>
      <div className="inline-block min-w-full border-b border-gray-200 align-middle overflow-x-auto w-full">
        <table className="min-w-full">
          <thead>
            <tr className="border-t border-gray-200">
              <th
                className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                scope="col"
              >
                <span className="lg:pl-2">ID</span>
              </th>
              <th
                className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                scope="col"
              >
                Commit
              </th>
              <th
                className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
                scope="col"
              >
                Author
              </th>
              <th
                className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
                scope="col"
              >
                Message
              </th>
              <th
                className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900 hidden md:table-cell"
                scope="col"
              >
                Duration
              </th>
              <th
                className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900 hidden md:table-cell"
                scope="col"
              >
                Time
              </th>
              <th
                className="border-b border-gray-200 bg-gray-50 py-3 pr-6 text-right text-sm font-semibold text-gray-900 hidden md:table-cell"
                scope="col"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 bg-white">
            {jobs?.list.map((job) => (
              <tr
                key={job.id}
                className="cursor-pointer hover:bg-gray-50"
                onClick={() => {
                  navigate(`/repo/${job.repoId}/job/${job.jobId}`);
                }}
              >
                <td className="px-6 py-3 text-sm font-medium text-gray-900">
                  <div className="flex items-center space-x-3 lg:pl-2">
                    <div
                      className={join(
                        [
                          "flex-shrink-0 w-2.5 h-2.5 rounded-full",
                          `${statusBgColor(job?.status ?? Status.Unknown)}`,
                          job?.status === Status.Running ? "animate-pulse" : "",
                        ],
                        " "
                      )}
                      aria-hidden="true"
                    />
                    <span>#{job.jobId}</span>
                  </div>
                </td>
                <td className="px-6 py-3 text-sm font-medium text-gray-500">
                  <span className="flex items-center">
                    <Commit
                      id={job.commits[0].id}
                      url={job.commits[0].url}
                      className="mr-2 text-blue-600 min-w-[50px]"
                    />
                    <Ref refs={job.ref} className="text-gray-900" />
                  </span>
                </td>
                <td className="hidden whitespace-nowrap px-6 py-3 text-left text-sm md:table-cell text-gray-500">
                  {job.commits[0].author.name}
                </td>
                <td className="hidden max-w-xs whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 md:table-cell truncate">
                  {job.commits[0].message}
                </td>
                <td className="hidden whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500 md:table-cell">
                  {job ? (
                    <DurationRealtime
                      start={job.startTime}
                      end={job.endTime}
                      running={job.status === Status.Running}
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td className="hidden whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500 md:table-cell">
                  {job.endTime.toRelative()}
                </td>
                <td className="whitespace-nowrap px-6 py-3 text-right text-sm font-medium hidden md:table-cell">
                  <span
                    className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      rerunJob.mutate({
                        repoId: job.repoId,
                        jobId: String(job.jobId),
                      });
                    }}
                  >
                    Rerun
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <SimplePagination
          page={page}
          perPage={perPage}
          count={jobs?.count ?? 0}
          previous={() => {
            setPage(page - 1 > 0 ? page - 1 : page);
          }}
          next={() => {
            setPage(page * perPage >= (jobs?.count ?? 0) ? page : page + 1);
          }}
        />
      </div>
    </>
  );
}

function jobStats(jobs: Job[]) {
  if (jobs.length === 0) {
    return {
      duration: Duration.fromMillis(0),
      datetime: DateTime.local(),
      status: Status.Unknown,
    };
  }

  let total = Duration.fromMillis(0);
  let count = 0;
  for (let job of jobs) {
    let duration = job.endTime.diff(job.startTime);
    if (duration.milliseconds < 0) {
      continue;
    }
    total = total.plus(duration);
    count += 1;
  }
  let averageMilli = Math.ceil(total.milliseconds / (count > 0 ? count : 1));
  let duration = Duration.fromMillis(averageMilli);

  return {
    duration,
    datetime: jobs[0].endTime,
    status: jobs.length > 0 ? jobs[0].status : Status.Unknown,
  };
}
