import { ChevronDownIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { join } from "lodash";
import { ElementType } from "react";

export enum Type {
  Simple,
  Normal,
}

export interface Props {
  type: Type;
  tag: ElementType;
  img: string;
  primaryText: string;
  primaryClass?: string;
  secondaryText?: string;
  secondaryClass?: string;
  className?: string;
  hideChevron?: boolean;
}

export function Card(props: Props) {
  const hasSecond =
    props.type === Type.Normal && props.secondaryText !== undefined;

  let ChevronIcon = ChevronDownIcon;
  if (hasSecond) {
    ChevronIcon = ChevronUpDownIcon;
  }

  let imgSize = "h-10 w-10";
  if (props.type === Type.Simple) {
    imgSize = "h-8 w-8";
  }

  return (
    <>
      <props.tag
        className={
          "group w-full rounded-md px-3.5 text-left text-sm font-medium z-50 py-2 " +
          (props.className ?? "")
        }
      >
        <span className="flex w-full items-center justify-between">
          <span className="flex min-w-0 items-center justify-between space-x-3">
            <img
              className={"flex-shrink-0 rounded-full " + imgSize}
              src={props.img}
              alt=""
            />
            <span className="flex min-w-0 flex-1 flex-col">
              <span
                className={join(
                  ["truncate text-sm font-medium", props.primaryClass],
                  " "
                )}
              >
                {props.primaryText}
              </span>
              {hasSecond ? (
                <>
                  <span
                    className={join(
                      ["truncate text-sm", props.secondaryClass],
                      " "
                    )}
                  >
                    {props.secondaryText!!}
                  </span>
                </>
              ) : (
                <></>
              )}
            </span>
          </span>
          {props.hideChevron ? (
            <></>
          ) : (
            <ChevronIcon
              className={join(
                ["h-5 w-5 flex-shrink-0", props.secondaryClass],
                " "
              )}
              aria-hidden="true"
            />
          )}
        </span>
      </props.tag>
    </>
  );
}
