import { Pipeline, Task } from "./pipeline";

export enum Status {
  Created = 0,
  Failed = -10,
  Success = 10,
  Running = 1,
  Pending = -5,
  Unknown = -9,
  Waiting = 2,
}

export enum IdType {
  Unknown = 0,
  Order = 1,
  Pipeline = 2,
  Task = 3,
  Stage = 4,
}

export enum LogType {
  Stdout = "stdout",
  Stderr = "stderr",
}

export enum EventType {
  Log = "log",
  Pipelines = "pipelines",
  Status = "status_change",
}

export interface JobState {
  status: Status;
  pipeStates: Array<PipelineState>;
  pipelines: Array<Pipeline>;
}

export const newJobState: () => JobState = () => {
  return {
    status: Status.Waiting,
    pipeStates: [],
    pipelines: [],
  };
};

export interface PipelineState {
  status: Status;
  taskStates: Array<TaskState>;
}

export interface TaskState {
  status: Status;
  stageStates: Array<StageState>;
}

export interface StageState {
  status: Status;
  logs: Array<Log>;
}

export interface Log {
  id: Id;
  content: string;
  time: string;
  type: LogType;
}

export interface Id {
  type: IdType;
  order: number;
  pipeline: number;
  task: number;
  stage: number;
}

export interface Event {
  id: Id;
  type: string;
  log?: Log;
  pipelines?: Array<Pipeline>;
  status?: Status;
}

export class State {
  jobState: JobState;

  constructor(jobState?: JobState) {
    if (!jobState) {
      jobState = newJobState();
    }
    this.jobState = jobState;
    if (this.jobState.pipelines === undefined) {
      this.jobState.pipelines = [];
    }
  }

  getPipe(pipeId: number): Pipeline | undefined {
    return this.jobState.pipelines[pipeId];
  }

  getTask(pipeId: number, taskId: number): Task | undefined {
    return this.jobState.pipelines[pipeId]?.tasks[taskId];
  }

  addLog(id: Id, log: Log) {
    this.addMissing(id);
    this.jobState.pipeStates[id.pipeline].taskStates[id.task].stageStates[
      id.stage
    ].logs.push(log);
  }

  setStatus(id: Id, status: Status) {
    this.addMissing(id);
    switch (id.type) {
      case IdType.Order:
        this.jobState.status = status;
        break;

      case IdType.Pipeline:
        this.jobState.pipeStates[id.pipeline].status = status;
        break;

      case IdType.Task:
        this.jobState.pipeStates[id.pipeline].taskStates[id.task].status =
          status;
        break;

      case IdType.Stage:
        this.jobState.pipeStates[id.pipeline].taskStates[id.task].stageStates[
          id.stage
        ].status = status;
        break;
    }
  }

  processEvt(evt: Event) {
    switch (evt.type) {
      case EventType.Log:
        this.addLog(evt.id, evt.log!!);
        break;
      case EventType.Pipelines:
        this.jobState.pipelines = evt.pipelines!!;
        break;
      case EventType.Status:
        this.setStatus(evt.id, evt.status!!);
        break;
    }
  }

  private addMissing(id: Id) {
    // add pipeline states
    if (!this.jobState.pipeStates === undefined) {
      this.jobState.pipeStates = [];
    }
    while (this.jobState.pipeStates.length <= id.pipeline) {
      this.jobState.pipeStates.push({ taskStates: [], status: Status.Waiting });
    }

    // add task states
    if (!this.jobState.pipeStates[id.pipeline].taskStates === undefined) {
      this.jobState.pipeStates[id.pipeline].taskStates = [];
    }
    while (this.jobState.pipeStates[id.pipeline].taskStates.length <= id.task) {
      this.jobState.pipeStates[id.pipeline].taskStates.push({
        status: Status.Waiting,
        stageStates: [],
      });
    }

    // add stage states
    if (
      !this.jobState.pipeStates[id.pipeline].taskStates[id.task].stageStates ===
      undefined
    ) {
      this.jobState.pipeStates[id.pipeline].taskStates[id.task].stageStates =
        [];
    }
    while (
      this.jobState.pipeStates[id.pipeline].taskStates[id.task].stageStates
        .length <= id.stage
    ) {
      this.jobState.pipeStates[id.pipeline].taskStates[
        id.task
      ].stageStates.push({
        status: Status.Waiting,
        logs: [],
      });
    }

    // if state missing
    if (
      this.jobState.pipeStates[id.pipeline].taskStates[id.task].stageStates[
        id.stage
      ].logs === undefined
    ) {
      this.jobState.pipeStates[id.pipeline].taskStates[id.task].stageStates[
        id.stage
      ].logs = [];
    }
  }
}
