import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { Event, State, Status } from "../../domain/state";
import { api, getData, Resp } from "../axios";

export interface Author {
  email: string;
  name: string;
}

export interface Commit {
  id: string;
  message: string;
  title: string;
  timestamp: DateTime;
  url: string;
  author: Author;
  added: Array<string>;
  modified: Array<string>;
  removed: Array<string>;
}

export interface Jid {
  repoId: number;
  jobId: number;
}

export interface Job extends Jid {
  id: number;
  sourceId: number;
  confPath: string;
  owner: string;
  repo: string;
  type: string;
  headCommit: string;
  ref: string;
  status: Status;
  createdAt: DateTime;
  startTime: DateTime;
  endTime: DateTime;
  commits: Array<Commit>;
}

export interface Log {
  id: number;
  repoId: number;
  jobId: number;
  content: Content;
  createdAt: string;
}

export interface Content {
  tasks?: Array<Task>;
}

export interface Task {
  stages: Array<Stage>;
  name: string;
}

export interface Stage {
  name: string;
  logs?: Array<Line>;
}

interface Line {
  content: string;
  time: DateTime;
  type: string;
}

// tailwind not support dynamic class name
export function statusBgColor(status: Status) {
  switch (status) {
    case Status.Pending:
      return "bg-yellow-400";
    case Status.Running:
      return "bg-blue-400";
    case Status.Success:
      return "bg-green-400";
    case Status.Failed:
      return "bg-red-600";
    default:
      return "bg-gray-400";
  }
}

// tailwind not support dynamic class name
export function statusTextColor(status: Status) {
  switch (status) {
    case Status.Pending:
      return "text-yellow-400";
    case Status.Running:
      return "text-blue-400";
    case Status.Success:
      return "text-green-500";
    case Status.Failed:
      return "text-red-600";
    default:
      return "text-gray-400";
  }
}

export const useJob = (repoId: number, jobId?: string) =>
  useQuery<Job | undefined>(["job", repoId, jobId], () =>
    repoId === -1 || jobId === undefined
      ? undefined
      : api.get(`job/${repoId}/${jobId}/detail`).then(getData)
  );

export const useRerunJob = () => {
  const qc = useQueryClient();

  return useMutation(
    (jid: { repoId: number; jobId?: string }) =>
      api.post<Resp<number>>(`job/${jid.repoId}/${jid.jobId}/rerun`),
    {
      onSuccess: (_, jid) => {
        qc.invalidateQueries(["jobs", jid.repoId]);
      },
    }
  );
};

export const useLog = (repoId: number, jobId?: string, isRunning?: boolean) => {
  const qc = useQueryClient();

  // initial logs
  const queryResult = useQuery<State | undefined>(
    ["state", repoId, jobId],
    () =>
      repoId === -1 || jobId === undefined
        ? undefined
        : api
            .get(`job/${repoId}/${jobId}/state`)
            .then((resp: any) => new State(resp.data["data"]))
  );

  // use websocket to fetch realtime logs
  useEffect(() => {
    if (repoId === undefined || jobId === undefined || !isRunning) {
      return;
    }

    // compute ws uri
    var loc = window.location;
    var wsProtocol;
    if (loc.protocol === "https:") {
      wsProtocol = "wss:";
    } else {
      wsProtocol = "ws:";
    }
    const wsUri = `${wsProtocol}//${loc.host}/api/v1/job/${repoId}/${jobId}/ws`;

    const ws = new WebSocket(wsUri);
    // add client ping
    ws!!.onopen = () => {
      let ping = () => {
        if (ws!!.readyState !== WebSocket.OPEN) {
          return;
        }
        ws!!.send("ping");
        setTimeout(() => {
          ping();
        }, 5000);
      };
      ping();
    };
    // handle logs
    ws!!.onmessage = (msg: MessageEvent) => {
      const evt: Event = JSON.parse(msg.data);
      qc.setQueryData(["state", repoId, jobId], (state: State | undefined) => {
        if (state === undefined) {
          state = new State();
        }
        let newState = cloneDeep(state);
        newState.processEvt(evt);
        return newState;
      });

      return () => {
        ws!!.close();
      };
    };
  }, [repoId, jobId, isRunning, qc]);

  return queryResult;
};
