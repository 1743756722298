import { Config, useDeleteConfig } from "../../api/services/Config";
import { Modal } from "../../components/modal/Modal";

interface Props {
  open: boolean;
  conf?: Config;
  onClose: () => void;
}

export function DelDialog(props: Props) {
  const delConf = useDeleteConfig();

  if (props.conf === undefined) {
    return <></>;
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        Delete config: {props.conf?.key}
      </h3>
      <div className="mt-2 max-w-xl text-sm text-gray-500">
        <p>Once you delete this config, you will not be able to recover it.</p>
      </div>
      <div className="mt-5">
        <button
          type="button"
          onClick={() => {
            delConf.mutate(props.conf!!, { onSuccess: props.onClose });
          }}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
        >
          Delete
        </button>
      </div>
    </Modal>
  );
}
