import { Menu, Transition } from "@headlessui/react";
import { join } from "lodash";
import { Fragment } from "react";
import { Card, Props as CardProps } from "./Card";

export interface Props extends Omit<Omit<CardProps, "tag">, "className"> {
  items: MenuItem[][];
  className?: string;
  itemClass?: string;
  cardClass?: string;
}

interface MenuItem {
  text: string;
  action: () => void;
}

export function CardMenu(props: Props) {
  return (
    <Menu as="div" className={"rounded-md " + (props.className ?? "")}>
      <Card
        type={props.type}
        tag={Menu.Button}
        img={props.img}
        primaryText={props.primaryText}
        primaryClass={props.primaryClass}
        secondaryText={props.secondaryText}
        secondaryClass={props.secondaryClass}
        className={props.cardClass}
        hideChevron={props.hideChevron}
      />

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            "absolute right-0 z-10 w-48 divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none " +
              props.itemClass ?? ""
          }
        >
          {props.items.map((group, gi) => {
            return (
              <div className="py-1" key={gi}>
                {group.map((item, i) => {
                  return (
                    <Menu.Item key={i}>
                      {({ active }) => (
                        <span
                          className={join(
                            [
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm cursor-pointer",
                            ],
                            " "
                          )}
                          onClick={item.action}
                        >
                          {item.text}
                        </span>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
