import { useLogout, useUserInfo } from "../../api/services/User";
import UnknownUser from "../../images/unknown-user.svg";
import { Type } from "../../components/card/Card";
import { CardMenu } from "../../components/card/Menu";

const LOGIN_URI = "/api/v1/user/login";

interface Props {
  type: Type;
  className?: string;
  cardClass?: string;
  primaryClass?: string;
  itemClass?: string;
  secondaryClass?: string;
  hideChevron?: boolean;
}

export function UserMenu(props: Props) {
  const { data: user } = useUserInfo();
  const logout = useLogout();

  if (user === undefined) {
    return (
      <>
        {props.type === Type.Normal ? (
          <div className="relative inline-block px-3 pt-1">
            <a
              className="flex items-center px-2 py-2 rounded-md hover:bg-gray-200"
              href={LOGIN_URI}
            >
              <img
                className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-100 mr-3"
                src={UnknownUser}
                alt=""
              />
              Login
            </a>
          </div>
        ) : (
          <div className="py-[5px]">
            <a
              className="sm:order-0 order-1 ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:ml-0"
              href={LOGIN_URI}
            >
              Login
            </a>
          </div>
        )}
      </>
    );
  }

  return (
    <CardMenu
      type={props.type}
      className={props.className}
      cardClass={props.cardClass}
      itemClass={props.itemClass}
      img={user.avatar}
      primaryText={user.fullName}
      primaryClass={props.primaryClass}
      secondaryText={"@" + user.username}
      secondaryClass={props.secondaryClass}
      hideChevron={props.hideChevron}
      items={[
        [
          {
            text: "Logout",
            action: () => {
              logout.mutate();
            },
          },
        ],
      ]}
    />
  );
}
