import { join } from "lodash";
import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useNavigate } from "react-router-dom";
import { statusBgColor } from "../../api/services/Job";
import { useRepos } from "../../api/services/Source";
import { Button } from "../../components/button/Button";
import { SimplePagination } from "../../components/pagination/Simple";
import { Commit, Ref } from "../../components/repo";
import { History } from "../../components/repo/History";
import { Status } from "../../domain/state";
import { useCurrentSrc } from "../../state";
import { useHeader } from "../header/Header";
import { AddDialog } from "./AddDialog";

export function RepoList() {
  const setHeader = useHeader();
  useEffect(() => {
    setHeader([{ name: "Repository" }]);
  }, [setHeader]);

  const navigate = useNavigate();
  const [srcId] = useCurrentSrc();

  const perPage = 20;
  const [page, setPage] = useState(1);
  const { data: repos } = useRepos(srcId, { page, perPage });

  const [adOpen, setAdOpen] = useState(false);

  return (
    <>
      <div className="flex m-3 rounded-md bg-white border border-gray-200 p-4">
        <span className="flex-1"></span>
        <Button text="Add" icon={Plus} onClick={() => setAdOpen(true)} />
      </div>
      {/* Projects table (small breakpoint and up) */}
      <div>
        <div className="inline-block min-w-full border-b border-gray-200 align-middle">
          <table className="min-w-full">
            <thead>
              <tr className="border-t border-gray-200">
                <th
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  scope="col"
                >
                  <span className="lg:pl-2">Name</span>
                </th>
                <th
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  scope="col"
                >
                  Health
                </th>
                <th
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
                  scope="col"
                >
                  Last Commit
                </th>
                <th
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
                  scope="col"
                >
                  Last Pipeline
                </th>
                <th
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900 hidden md:table-cell"
                  scope="col"
                >
                  Duration
                </th>
                <th
                  className="border-b border-gray-200 bg-gray-50 py-3 pr-6 text-right text-sm font-semibold text-gray-900 hidden md:table-cell"
                  scope="col"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {repos?.list.map((repo) => (
                <tr
                  key={repo.id}
                  className="cursor-pointer hover:bg-gray-50"
                  onClick={() => {
                    navigate(`/repo/${repo.id}/job`);
                  }}
                >
                  <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
                    <div className="flex items-center space-x-3 lg:pl-2">
                      <div
                        className={join(
                          [
                            "flex-shrink-0 w-2.5 h-2.5 rounded-full",
                            `${statusBgColor(
                              repo.lastJob?.status ?? Status.Unknown
                            )}`,
                            repo.lastJob?.status === Status.Running
                              ? "animate-pulse"
                              : "",
                          ],
                          " "
                        )}
                        aria-hidden="true"
                      />
                      <span className="text-gray-900">
                        {repo.owner}/{repo.name}{" "}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-3 text-sm font-medium text-gray-500">
                    <History history={repo.history} />
                  </td>
                  <td className="hidden whitespace-nowrap px-6 py-3 text-right text-sm md:table-cell">
                    {repo.lastJob ? (
                      <span className="flex items-center">
                        <Commit
                          id={repo.lastJob.commits[0].id}
                          url={repo.lastJob.commits[0].url}
                          className="mr-2 text-blue-600"
                        />
                        <Ref
                          refs={repo.lastJob.ref}
                          className="text-gray-900"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="hidden whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500 md:table-cell">
                    {repo.lastJob?.endTime.toRelative()}
                  </td>
                  <td className="hidden whitespace-nowrap px-6 py-3 text-left text-sm text-gray-500 md:table-cell">
                    {/*repo.lastJob
                      ? repo.lastJob.endTime
                          .diff(repo.lastJob.startTime)
                          .toHuman({ unitDisplay: "short" })
                          : "-"*/}
                  </td>
                  <td className="whitespace-nowrap px-6 py-3 text-right text-sm font-medium hidden md:table-cell">
                    <a
                      href="/"
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Edit
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <SimplePagination
            page={page}
            perPage={perPage}
            count={repos?.count ?? 0}
            previous={() => setPage(page - 1 > 0 ? page - 1 : page)}
            next={() =>
              setPage(page * perPage >= (repos?.count ?? 0) ? page : page + 1)
            }
          />
        </div>
      </div>
      <AddDialog open={adOpen} setOpen={setAdOpen} />
    </>
  );
}
