import { Modal } from "../../components/modal/Modal";
import { Button } from "../../components/button/Button";
import { useEffect, useState } from "react";
import { ConfigIO, Type } from "../../api/services/Config";
import { inputChange } from "../../utils";
import TextareaAutosize from "react-textarea-autosize";

interface Props {
  open: boolean;
  conf: ConfigIO;
  confirmText?: string;

  onClose: () => void;
  onConfirm: (conf: ConfigIO) => void;
}

export function Dialog(props: Props) {
  const [conf, setConf] = useState(props.conf);
  const handleChange = inputChange(conf, setConf);

  useEffect(() => {
    setConf({
      ...conf,
      ...props.conf,
    });
  }, [props.conf]); // eslint-disable-line

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <h3 className="text-lg font-medium leading-6 text-gray-900 mb-6">
        Add config
      </h3>
      <form className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-600">Key</label>
          <div className="mt-1">
            <input
              type="text"
              name="key"
              value={conf.key}
              onChange={handleChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Type
          </label>
          <div className="mt-1">
            <select
              name="type"
              value={conf.type}
              onChange={handleChange}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
            >
              <option value={Type.Text}>Text</option>
              <option value={Type.File}>File</option>
            </select>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Value
          </label>
          <div className="mt-1">
            <TextareaAutosize
              minRows={3}
              maxRows={15}
              name="value"
              value={conf.value}
              onChange={handleChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
      </form>

      <div className="flex flex-row-reverse mt-5">
        <Button
          text={props.confirmText ?? "Add"}
          onClick={() => {
            props.onConfirm(conf);
          }}
          className="bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500"
        />
        <Button text="Cancel" onClick={props.onClose} className="px-5 mr-2" />
      </div>
    </Modal>
  );
}
