import { Dispatch, SetStateAction, useMemo } from "react";
import { DesktopNav } from "./DesktopNav";
import { MobileNav } from "./MobileNav";
import { useNavigation, useNavKey } from "../../state/navigation";

export interface Team {
  name: string;
  href: string;
  bgColorClass: string;
}

const teams = [
  { name: "Engineering", href: "#", bgColorClass: "bg-indigo-500" },
  { name: "Human Resources", href: "#", bgColorClass: "bg-green-500" },
  { name: "Customer Success", href: "#", bgColorClass: "bg-yellow-500" },
];

interface NaviProps {
  mobileNavOpen: boolean;
  setMobileNavOpen: Dispatch<SetStateAction<boolean>>;
}

export function Navigation(props: NaviProps) {
  const [nav] = useNavigation();
  const navKey = useNavKey();

  const curNav = useMemo(() => {
    return nav.withCurrent(navKey);
  }, [nav, navKey]);

  return (
    <>
      <MobileNav
        navOpen={props.mobileNavOpen}
        setNavOpen={props.setMobileNavOpen}
        navigation={curNav.navList}
        teams={teams}
      />
      <div className="hidden lg:fixed lg:flex">
        <DesktopNav navigation={curNav.navList} teams={teams} />
      </div>
    </>
  );
}
