import { useQuery } from "@tanstack/react-query";
import { Status } from "../../domain/state";
import { api, getData } from "../axios";
import { ListVO, Page } from "./common";
import { Job } from "./Job";

export interface Repo {
  id: number;
  sourceId: number;
  owner: string;
  name: string;
  latestStatus: Status;
  history: Status[];
  lastJob?: Job;
}
export interface OriginRepo {
  id: number;
  owner: string;
  name: string;
  avatar: string;
  description: string;
}

export const useJobs = (repoId: number, page: Page) =>
  useQuery<ListVO<Job> | undefined>({
    queryKey: ["jobs", repoId, page.page, page.perPage],
    queryFn: () =>
      repoId !== -1
        ? api.get(`repo/${repoId}/jobs`, { params: page }).then(getData)
        : undefined,
    staleTime: 30 * 1000,
    keepPreviousData: true,
  });

export const useRepoDetail = (repoId: number) =>
  useQuery<Repo | undefined>(["repo", repoId], () =>
    repoId !== -1 ? api.get(`repo/${repoId}/detail`).then(getData) : undefined
  );
