import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api, getData } from "../axios";

export {};

export enum Type {
  Text = 0,
  File = 1,
}

export enum Scope {
  Global = 0,
  Source = 1,
  Repo = 2,
}

export interface ConfigIO {
  scope: Scope;
  sourceId: number;
  repoId: number;
  type: Type;
  key: string;
  value: string;
}

export interface Config extends ConfigIO {
  id: number;
  userId: number;
}

export const useConfigs = (scope: Scope, srcId?: number, repoId?: number) =>
  useQuery<Config[]>({
    queryKey: ["configs", scope, srcId ?? -1, repoId ?? -1],
    queryFn: () =>
      api
        .get(`config/list`, {
          params: { scope, srcId, repoId },
        })
        .then(getData),
  });

export const useAddConfig = () => {
  const qc = useQueryClient();
  return useMutation(
    (conf: ConfigIO) =>
      api.post(`config/add`, { ...conf, type: Number(conf.type) }),
    {
      onSuccess: (_, conf) => {
        qc.invalidateQueries([
          "configs",
          conf.scope,
          conf.sourceId,
          conf.repoId,
        ]);
      },
    }
  );
};

export const useUpdateConfig = () => {
  const qc = useQueryClient();
  return useMutation(
    (arg: { id: number; conf: ConfigIO }) =>
      api.post(`config/${arg.id}/update`, {
        ...arg.conf,
        id: arg.id,
        type: Number(arg.conf.type),
      }),
    {
      onSuccess: (_, arg) => {
        qc.invalidateQueries([
          "configs",
          arg.conf.scope,
          arg.conf.sourceId,
          arg.conf.repoId,
        ]);
      },
    }
  );
};

export const useDeleteConfig = () => {
  const qc = useQueryClient();
  return useMutation(
    // srcId & repoId is used to invalidate queries
    (conf: Config) => api.post(`config/${conf.id}/delete`),
    {
      onSuccess: (_, conf) => {
        qc.invalidateQueries([
          "configs",
          conf.scope,
          conf.sourceId,
          conf.repoId,
        ]);
      },
    }
  );
};
