interface Props {
  page: number;
  perPage: number;
  count: number;
  previous: () => void;
  next: () => void;
}

export function SimplePagination(props: Props) {
  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing{" "}
          <span className="font-medium">
            {(props.page - 1) * props.perPage + 1}
          </span>{" "}
          to <span className="font-medium">{props.page * props.perPage}</span>{" "}
          of <span className="font-medium">{props.count}</span> results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <button
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={props.previous}
        >
          Previous
        </button>
        <button
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={props.next}
        >
          Next
        </button>
      </div>
    </nav>
  );
}
