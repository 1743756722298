import { CheckIcon } from "@heroicons/react/20/solid";
import { join } from "lodash";
import { X } from "react-feather";
import { Code } from "../../components/code/Code";
import { Status } from "../../domain/state";

interface Step {
  name: string;
  description: string;
  href: string;
  status: Status;
  code?: string;
}

interface Props {
  steps: Step[];
  last?: boolean;
  status?: Status;
}

export function Stages(props: Props) {
  return (
    <nav aria-label="Progress">
      <ol className="overflow-hidden">
        {props.steps.map((stage, si) => (
          <li
            key={si}
            className={join(
              [si !== props.steps.length - 1 ? "pb-10" : "", "relative"],
              " "
            )}
          >
            <>
              {si !== props.steps.length - 1 ? (
                <div
                  className="absolute h-[calc(100%-3rem)] top-10 left-4 -ml-px mt-0.5 mb-2 w-0.5 bg-gray-300"
                  aria-hidden="true"
                />
              ) : null}
              <span className="group relative flex items-start">
                <span className="flex h-9 items-center">
                  <StatusCircle status={stage.status} />
                </span>
                <span className="ml-4 flex min-w-0 flex-col flex-1">
                  <span className="text-base font-medium">{stage.name}</span>
                  <span className="text-sm text-gray-500">
                    {stage.description}
                  </span>
                  {stage.code ? (
                    <span className="text-sm">
                      <Code code={stage.code} />
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </span>
            </>
          </li>
        ))}
      </ol>
    </nav>
  );
}

function CompletedCircle() {
  return (
    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-green-600 group-hover:bg-green-800">
      <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
    </span>
  );
}

function WaitingCircle() {
  return (
    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-400 bg-white">
      <span className="h-2.5 w-2.5 rounded-full bg-gray-400" />
    </span>
  );
}

function RunningCircle() {
  return (
    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-400 bg-white">
      <span className="h-2.5 w-2.5 rounded-full bg-blue-400 animate-pulse" />
    </span>
  );
}

function FailedCircle() {
  return (
    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-red-600 group-hover:bg-red-800">
      <X className="h-5 w-5 text-white" aria-hidden="true" />
    </span>
  );
}

function UnknownCircle() {
  return (
    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-gray-400 group-hover:bg-gray-500">
      <div className="h-5 w-5 font-medium text-white cursor-default text-center">
        ?
      </div>
    </span>
  );
}

interface SCProps {
  status?: Status;
}

function StatusCircle(props: SCProps) {
  switch (props.status) {
    case Status.Running:
      return <RunningCircle />;
    case Status.Failed:
      return <FailedCircle />;
    case Status.Success:
      return <CompletedCircle />;
    case Status.Waiting:
      return <WaitingCircle />;
    default:
      return <UnknownCircle />;
  }
}
