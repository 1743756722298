import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { User } from "react-feather";
import { useNavigate } from "react-router-dom";
import { api, getData } from "../../api/axios";
import { useCurrentSrc } from "../../state";

export interface User {
  id: number;
  avatar: string;
  email: string;
  fullName: string;
  username: string;
}

export interface UserWithRole extends User {
  role: number;
  roleName: string;
}

export const useUserInfo = () =>
  useQuery<User | undefined>(["user-info"], () =>
    api.get("user/info").then(getData)
  );

export const logout = (): Promise<void> => api.post("user/logout");

export const useLogout = () => {
  const qc = useQueryClient();
  const [, setCurSrc] = useCurrentSrc();
  const navigate = useNavigate();

  return useMutation(
    async () => {
      await api.post("user/logout");
      setCurSrc(-1);
    },
    {
      onSuccess: () => {
        qc.invalidateQueries(["user-info"]);
        navigate("/");
        window.location.reload();
      },
    }
  );
};
