import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api, getData } from "../../api/axios";
import { OriginRepo, Repo } from "./Repo";
import GitlabLogo from "../../images/gitlab.svg";
import GiteaLogo from "../../images/gitea.svg";
import { ListVO, Page } from "./common";
import { UserWithRole } from "./User";

export enum Type {
  Gitea = "gitea",
  Gitlab = "gitlab",
}

export function sourceLogo(t: Type | undefined) {
  let logo = GitlabLogo;
  switch (t) {
    case Type.Gitea:
      logo = GiteaLogo;
      break;
    case Type.Gitlab:
      logo = GitlabLogo;
      break;
    default:
      logo = GiteaLogo;
  }
  return logo;
}

export interface SourceIO {
  name: string;
  type: Type;
  url: string;
  username: string;
  accessToken: string;
}

export interface Source extends SourceIO {
  id: number;
  userId: number;
}

export const useSources = () =>
  useQuery<Source[]>(["sources"], () => api.get("source/list").then(getData));

export const useAddSource = () => {
  const qc = useQueryClient();
  return useMutation((src: SourceIO) => api.post(`source/add`, src), {
    onSuccess: () => {
      qc.invalidateQueries(["sources"]);
    },
  });
};

export const useRepos = (srcId: number, page: Page) =>
  useQuery<ListVO<Repo> | undefined>({
    queryKey: ["repos", srcId, page.page, page.perPage],
    queryFn: () =>
      srcId !== -1
        ? api.get(`source/${srcId}/connected`, { params: page }).then(getData)
        : undefined,
    staleTime: 30 * 1000,
    keepPreviousData: true,
  });

export const useConnectRepo = (srcId: number) => {
  const qc = useQueryClient();
  return useMutation(
    (repo: string) => api.post(`source/${srcId}/connect?repo=${repo}`),
    {
      onSuccess: () => {
        qc.invalidateQueries(["repos", srcId]);
      },
    }
  );
};

export const useSearchRepos = (srcId: number, query: string) =>
  useQuery<OriginRepo[]>({
    queryKey: ["repo-search", srcId, query],
    queryFn: () =>
      srcId === -1
        ? []
        : api
            .post(
              `source/${srcId}/search?query=${query}&` +
                `page=${1}&perPage=${20}`
            )
            .then(getData),
    keepPreviousData: true,
  });

export const useSrcUsers = (srcId: number) =>
  useQuery<UserWithRole[]>({
    queryKey: ["src-users", srcId],
    queryFn: () =>
      srcId === -1 ? [] : api.get(`source/${srcId}/users`).then(getData),
    keepPreviousData: true,
  });
