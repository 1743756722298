import { Duration } from "luxon";
import { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";

export function displayTime(time: any): string {
  return new Date(time).toLocaleString("zh-cn", { timeZone: "Asia/Shanghai" });
}

export function durationStr(duration: Duration | undefined): string {
  if (!duration) {
    return "";
  }

  duration = duration.rescale();
  var res = "";
  if (duration.as("days") >= 1) {
    res += `${duration.days}days `;
  }
  if (duration.as("hours") >= 1) {
    res += `${duration.hours}hours `;
  }
  if (duration.as("minutes") >= 1) {
    res += `${duration.minutes}min `;
  }
  if (duration.as("seconds") >= 1 || res === "") {
    res += `${duration.seconds}s`;
  }
  return res;
}

export function preventPropagation(evt: any) {
  evt.stopPropagation();
}

export function inputChange(
  prevVal: any,
  setFunc: Dispatch<SetStateAction<any>>
) {
  return (evt: any) => {
    const trg = evt.target;
    const value = trg.type === "checkbox" ? trg.checked : trg.value;
    const name = trg.name;

    setFunc({
      ...prevVal,
      [name]: value,
    });
  };
}

export function useParamsNumber(key: string) {
  const params = useParams();
  return params[key] ? parseInt(params[key]!!) : null;
}
