import { atom, useAtom } from "jotai";
import { ComponentType, SVGProps } from "react";
import { cloneDeep, concat } from "lodash";
import {
  ServerStackIcon,
  HomeIcon,
  Cog6ToothIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";

export interface NavItem {
  name: string;
  key: string;
  href: string;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  current: boolean;
  open?: boolean;
  children?: NavItem[];
}

export enum NavKey {
  Repo = "repo",
  RepoJob = "repo/job",
  RepoConfig = "repo/config",
  Config = "config",
  Source = "source",
}

export class Navigation {
  navList: NavItem[];
  keyMap: Map<string, number[]> = new Map();

  constructor(navList: NavItem[]) {
    this.navList = navList;
    this.fillKeyMap(navList, []);
  }

  private fillKeyMap(navList: NavItem[], prefix: number[]) {
    for (let i = 0; i < navList.length; i++) {
      let nav = navList[i];
      this.keyMap.set(nav.key, concat(prefix, i));
      if (nav.children) {
        this.fillKeyMap(nav.children, [i]);
      }
    }
  }

  private allFalse() {
    // NOTE: only work in two levels
    for (let i = 0; i < this.navList.length; i++) {
      this.navList[i].current = false;
      this.navList[i].open = false;
      for (let j = 0; j < (this.navList[i].children?.length ?? 0); j++) {
        this.navList[i].children!![j].current = false;
        this.navList[i].children!![j].open = false;
      }
    }
  }

  private getIdx(key: string) {
    return this.keyMap.get(key) ?? [];
  }

  private getItem(key: string) {
    const idx = this.getIdx(key);
    var curItem = this.navList;
    for (let i = 0; i < idx.length - 1; i++) {
      curItem[i].open = true;
      curItem = curItem[i].children ?? [];
    }
    return curItem[idx[idx.length - 1]];
  }

  private setCurrent(key: string) {
    this.allFalse();
    this.getItem(key).current = true;
  }

  withCurrent(key: string) {
    let newNav = cloneDeep(this);
    newNav.setCurrent(key);
    return newNav;
  }

  withRepo(repoId: number) {
    let newNav = cloneDeep(this);

    let jobItem = newNav.getItem("repo/job");
    jobItem.href = `repo/${repoId}/job`;

    let confItem = newNav.getItem("repo/config");
    confItem.href = `repo/${repoId}/config`;

    return newNav;
  }
}

export const navList = [
  {
    name: "Repository",
    key: NavKey.Repo,
    href: "/",
    icon: HomeIcon,
    current: true,
    regex: /^\/$/,
    children: [
      {
        name: "Job",
        key: NavKey.RepoJob,
        href: "#",
        icon: RectangleGroupIcon,
        current: false,
        regex: /^\/repo\/\d+\/job/,
      },
      {
        name: "Config",
        key: NavKey.RepoConfig,
        href: "#",
        icon: Cog6ToothIcon,
        current: false,
        regex: /^\/repo\/\d+\/config/,
      },
    ],
  },
  {
    name: "Config",
    key: NavKey.Config,
    href: "/config",
    icon: Cog6ToothIcon,
    current: false,
    regex: /^\/config/,
  },
  {
    name: "Source",
    key: NavKey.Source,
    href: "/source",
    icon: ServerStackIcon,
    current: false,
    regex: /^\/source/,
  },
];

function selectedKey(path: string): string {
  let selected = NavKey.Repo;
  for (let i = 0; i < navList.length; i++) {
    if (navList[i].regex.test(path)) {
      selected = navList[i].key;
      break;
    }

    // inner loop
    for (let j = 0; j < (navList[i].children?.length ?? 0); j++) {
      if (navList[i].children!![j].regex.test(path)) {
        selected = navList[i].children!![j].key;
        break;
      }
    }
  }
  return selected;
}

const navAtom = atom<Navigation>(new Navigation(navList));
export const useNavigation = () => {
  return useAtom(navAtom);
};
export const useNavKey = () => {
  const location = useLocation();
  return selectedKey(location.pathname);
};
