import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { Config, Scope, useConfigs } from "../../api/services/Config";
import { Button } from "../../components/button/Button";
import { AddDialog } from "./AddDialog";
import { DelDialog } from "./DelDialog";
import { EditDialog } from "./EditDialog";
import { useCurrentSrc, useRepoId } from "../../state";
import { NavKey, useNavKey } from "../../state/navigation";
import { useHeader } from "../header/Header";

export function ConfigList() {
  const [srcId] = useCurrentSrc();
  const repoId = useRepoId();

  // get scope
  const navKey = useNavKey();
  var curScope = Scope.Source;
  if (navKey === NavKey.RepoConfig) {
    curScope = Scope.Repo;
  }

  const { data: configs } = useConfigs(curScope, srcId, repoId);

  const [adOpen, setAdOpen] = useState(false);
  const [edOpen, setEdOpen] = useState(false);
  const [edConf, setEdConf] = useState<Config | undefined>(undefined);
  const [delOpen, setDelOpen] = useState(false);
  const [delConf, setDelConf] = useState<Config | undefined>(undefined);

  const setHeader = useHeader();
  useEffect(() => {
    setHeader([{ name: "Config" }]);
  }, []);

  return (
    <>
      <div className="flex m-3 rounded-md bg-white border border-gray-200 p-4">
        <span className="flex-1"></span>
        <Button
          text="Add"
          icon={Plus}
          onClick={() => {
            setAdOpen(true);
          }}
        />
      </div>
      <div>
        <table className="min-w-full">
          <thead>
            <tr className="border-t border-gray-200">
              <th className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900">
                <span className="pl-2">Key</span>
              </th>
              <th className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900">
                Type
              </th>
              <th className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 hidden md:table-cell">
                Value
              </th>
              <th className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900">
                Operation
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 bg-white">
            {configs?.map((conf) => (
              <tr key={conf.id} className="hover:bg-gray-50">
                <td className="whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
                  {conf.key}
                </td>
                <td className="whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
                  {conf.type}
                </td>
                <td className="px-6 py-3 text-sm font-medium text-gray-900">
                  {conf.value}
                </td>
                <td className="px-6 py-3 text-sm font-medium text-gray-900">
                  <span
                    onClick={() => {
                      setEdConf(conf);
                      setEdOpen(true);
                    }}
                    className="cursor-pointer text-indigo-600 hover:text-indigo-700"
                  >
                    Edit
                  </span>
                  <span
                    onClick={() => {
                      setDelConf(conf);
                      setDelOpen(true);
                    }}
                    className="cursor-pointer text-red-600 hover:text-red-700 ml-4"
                  >
                    Delete
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AddDialog
        scope={curScope}
        srcId={srcId}
        repoId={repoId}
        open={adOpen}
        onClose={() => {
          setAdOpen(false);
        }}
      />
      <EditDialog
        conf={edConf}
        open={edOpen}
        onClose={() => {
          setEdOpen(false);
        }}
      />
      <DelDialog
        conf={delConf}
        open={delOpen}
        onClose={() => {
          setDelOpen(false);
        }}
      />
    </>
  );
}
