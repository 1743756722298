import { sourceLogo, useSources } from "../../api/services/Source";
import { Type } from "../../components/card/Card";
import { CardMenu } from "../../components/card/Menu";
import { useEffect } from "react";
import { useCurrentSrc } from "../../state";
import { useNavigate } from "react-router-dom";

interface Props {
  className?: string;
  cardClassName?: string;
  itemClassName?: string;
  primaryClass?: string;
  secondaryClass?: string;
}

export function SourceBtn(props: Props) {
  const { data } = useSources();
  const [srcId, setSrcId] = useCurrentSrc();
  const navigate = useNavigate();

  useEffect(() => {
    if (data !== undefined && data.length > 0 && srcId === -1) {
      setSrcId(data[0].id);
    }
  }, [data, srcId, setSrcId]);

  let sources = data;
  if (sources === undefined) {
    sources = [];
  }

  // TODO: use map if there are many items in sources
  let currentSrc = sources.length > 0 ? sources[0] : undefined;
  for (let src of sources) {
    if (src.id === srcId) {
      currentSrc = src;
    }
  }

  return (
    <CardMenu
      type={Type.Normal}
      className={props.className}
      cardClass={props.cardClassName}
      itemClass={props.itemClassName}
      img={sourceLogo(currentSrc?.type)}
      primaryText={currentSrc?.name ?? ""}
      primaryClass={props.primaryClass}
      secondaryText={`@${currentSrc?.type ?? ""}`}
      secondaryClass={props.secondaryClass}
      items={[
        sources.map((source) => {
          return {
            text: source.name,
            action: () => {
              setSrcId(source.id);
              navigate("/");
            },
          };
        }),
      ]}
    />
  );
}
