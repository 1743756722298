import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./pages/nav/Navigation";
import { Header } from "./pages/header/Header";
import { RepoList } from "./pages/repo/RepoList";
import { JobList } from "./pages/job/JobList";
import { JobDetail } from "./pages/job/JobDetail";
import { SourceList } from "./pages/source/SourceList";
import { ConfigList } from "./pages/config/ConfigList";
import { SourceDetail } from "./pages/source/Detail";
import { Users } from "./pages/source/Users";

export default function Jiheng() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Router>
        <div>
          <Navigation
            mobileNavOpen={sidebarOpen}
            setMobileNavOpen={setSidebarOpen}
          />

          <div className="flex flex-col lg:pl-64">
            <main className="flex-1">
              <Header
                setNavOpen={setSidebarOpen}
                pages={[
                  { name: "Repository", href: "#" },
                  { name: "jiheng/jiheng", href: "#" },
                ]}
              />
              <Routes>
                <Route path="/" element={<RepoList />} />
                <Route path="/repo/:repoId/job" element={<JobList />} />
                <Route path="/repo/:repoId/config" element={<ConfigList />} />
                <Route
                  path="/repo/:repoId/job/:jobId"
                  element={<JobDetail />}
                />
                <Route path="/source" element={<SourceList />} />
                <Route path="/source/:srcId" element={<SourceDetail />}>
                  <Route path="" element={<Users />} />
                </Route>
                <Route path="/config" element={<ConfigList />} />
                <Route path="*" element={<div>404</div>} />
              </Routes>
            </main>
          </div>
        </div>
      </Router>
    </>
  );
}
