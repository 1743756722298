import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Bars3CenterLeftIcon } from "@heroicons/react/24/outline";
import { atom, useAtom } from "jotai";
import { join } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { Type } from "../../components/card/Card";
import { UserMenu } from "../nav/UserMenu";

export const headerAtom = atom<Page[]>([]);

export function useHeader() {
  const [, setPages] = useAtom(headerAtom);
  return setPages;
}

interface Props {
  setNavOpen: Dispatch<SetStateAction<boolean>>;
  pages?: Page[];
}

export interface Page {
  name: string;
  href?: string;
}

export function Header(props: Props) {
  const [pages] = useAtom(headerAtom);

  return (
    <>
      <div className="flex border-b border-gray-200 bg-white">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
          onClick={() => props.setNavOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <nav className="flex pl-2" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            {pages.map((page, i, row) => (
              <li key={page.name}>
                <div className="flex items-center">
                  {i !== 0 ? (
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <></>
                  )}
                  {page.href ? (
                    <Link
                      to={page.href}
                      className={join(
                        [
                          "ml-4 text-base font-medium",
                          row.length - 1 === i
                            ? "text-gray-700"
                            : "text-gray-400 hover:text-gray-700",
                        ],
                        " "
                      )}
                    >
                      {page.name}
                    </Link>
                  ) : (
                    <span
                      className={join(
                        [
                          "ml-4 text-base font-medium",
                          row.length - 1 === i
                            ? "text-gray-700"
                            : "text-gray-400 hover:text-gray-700",
                        ],
                        " "
                      )}
                    >
                      {page.name}
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="flex flex-1 justify-between px-4 py-2">
          <div className="flex flex-1"></div>
          <div>
            <UserMenu
              type={Type.Simple}
              className="ml-3 group"
              cardClass="hover:bg-gray-100"
              secondaryClass="text-gray-400"
              hideChevron={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
