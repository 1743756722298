import { ConfigIO, Scope, Type, useAddConfig } from "../../api/services/Config";
import { Dialog } from "./Dialog";

interface adProps {
  scope: Scope;
  srcId: number;
  repoId: number;
  open: boolean;
  onClose: () => void;
}

export function AddDialog(props: adProps) {
  const addConf = useAddConfig();

  return (
    <Dialog
      open={props.open}
      conf={{
        scope: props.scope,
        sourceId: props.srcId,
        repoId: props.repoId,
        type: Type.Text,
        key: "",
        value: "",
      }}
      onClose={props.onClose}
      onConfirm={(conf: ConfigIO) =>
        addConf.mutate(conf, {
          onSuccess: props.onClose,
        })
      }
    />
  );
}
