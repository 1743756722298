import { UserGroupIcon } from "@heroicons/react/24/outline";
import { join } from "lodash";
import { Outlet } from "react-router-dom";

const subNavigation = [
  { name: "Users", href: "#", icon: UserGroupIcon, current: true },
];

export function SourceDetail() {
  return (
    <div className="p-4">
      <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x bg-white rounded-md">
        <aside className="py-6 lg:col-span-2">
          <nav className="space-y-1">
            {subNavigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={join(
                  [
                    item.current
                      ? "border-teal-500 bg-teal-50 text-teal-700 hover:bg-teal-50 hover:text-teal-700"
                      : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                    "group flex items-center border-l-4 px-3 py-2 text-sm font-medium",
                  ],
                  " "
                )}
                aria-current={item.current ? "page" : undefined}
              >
                <item.icon
                  className={join(
                    [
                      item.current
                        ? "text-teal-500 group-hover:text-teal-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-1 mr-3 h-6 w-6 flex-shrink-0",
                    ],
                    " "
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
              </a>
            ))}
          </nav>
        </aside>
        <div className="py-6 px-4 sm:p-6 lg:pb-8 lg:col-span-10 min-h-[800px]">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
