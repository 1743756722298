import { join } from "lodash";
import { statusBgColor } from "../../api/services/Job";
import { Status } from "../../domain/state";

interface Props {
  history: Status[];
}

export function History(props: Props) {
  return (
    <div className="flex">
      {props.history.map((status, i) => (
        <span
          key={i}
          className={join(
            [
              "w-1 h-6 inline-block mr-1 rounded-lg",
              `${statusBgColor(status)}`,
              status === Status.Running ? "animate-pulse" : "",
            ],
            " "
          )}
        />
      ))}
    </div>
  );
}
