import { GitBranch, Tag } from "react-feather";
import { preventPropagation } from "../../utils";

interface CommitProps {
  id: string;
  url: string;
  className?: string;
}

export function Commit(props: CommitProps) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={props.url}
      className={props.className}
      onClick={preventPropagation}
    >
      {props.id.substring(0, 6)}
    </a>
  );
}

interface RefProps {
  refs: string;
  className?: string;
}

export function Ref(props: RefProps) {
  let icon = <GitBranch size={16} className="top-1" />;
  if (props.refs.startsWith("refs/tags")) {
    icon = <Tag size={16} />;
  }
  let ref = props.refs.replace(/^refs\/.*?\//, "");
  return (
    <span className={"flex whitespace-nowrap items-center " + props.className}>
      {icon}
      <span className="ml-[2px]">{ref}</span>
    </span>
  );
}
