import { join } from "lodash";
import { Team } from "./Navigation";
import LogoImg from "../../images/logo.png";
import { SourceBtn } from "./SourceBtn";
import { Link } from "react-router-dom";
import { NavItem } from "../../state/navigation";

interface DesktopNavProps {
  navigation: NavItem[];
  teams: Team[];
}

export function DesktopNav(props: DesktopNavProps) {
  return (
    <>
      {/* Static sidebar for desktop */}
      <div className="fixed inset-y-0 flex w-64 flex-col border-r border-cyan-600 pt-5 pb-4 bg-gradient-to-br from-sky-800 to-cyan-600">
        <div className="flex flex-shrink-0 items-center px-6">
          <img className="h-8 w-auto" src={LogoImg} alt="Your Company" />
          <span className="ml-2 text-xl font-bold text-cyan-100">
            JiHeng CI
          </span>
        </div>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="mt-4 pt-1 flex h-0 flex-1 flex-col overflow-y-auto">
          <SourceBtn
            className="px-3 text-left pt-0"
            cardClassName="hover:bg-cyan-600 ring-2 ring-cyan-600"
            primaryClass="text-cyan-100"
            secondaryClass="text-cyan-500"
            itemClassName="mr-3"
          />
          {/* Navigation */}
          <nav className="mt-6 px-3">
            <div className="space-y-1">
              {props.navigation.map((item, idx) => (
                <div key={idx} className="space-y-1">
                  <Link
                    to={item.href}
                    className={join(
                      [
                        item.current
                          ? "bg-cyan-600 text-cyan-100"
                          : "text-cyan-500 hover:text-cyan-300 hover:bg-cyan-600",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                      ],
                      " "
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.icon ? (
                      <item.icon
                        className={join(
                          [
                            item.current
                              ? "text-cyan-100"
                              : "text-cyan-500 group-hover:text-cyan-300",
                            "mr-3 flex-shrink-0 h-6 w-6",
                          ],
                          " "
                        )}
                        aria-hidden="true"
                      />
                    ) : (
                      <></>
                    )}
                    {item.name}
                  </Link>
                  {item.open &&
                    item.children?.map((subItem) => (
                      <Link
                        to={subItem.href}
                        key={subItem.key}
                        className={join(
                          [
                            subItem.current
                              ? "bg-cyan-600 text-cyan-100"
                              : "text-cyan-500 hover:text-cyan-300 hover:bg-cyan-600",
                            "group flex items-center pl-6 px-2 py-2 text-sm font-medium rounded-md",
                          ],
                          " "
                        )}
                      >
                        {subItem.icon ? (
                          <subItem.icon
                            className={join(
                              [
                                subItem.current
                                  ? "text-cyan-100"
                                  : "text-cyan-500 group-hover:text-cyan-300",
                                "mr-3 flex-shrink-0 h-6 w-6",
                              ],
                              " "
                            )}
                            aria-hidden="true"
                          />
                        ) : (
                          <></>
                        )}
                        {subItem.name}
                      </Link>
                    ))}
                </div>
              ))}
            </div>
            <div className="mt-8">
              {/* Secondary navigation */}
              <h3
                className="px-3 text-sm font-medium text-cyan-300"
                id="desktop-teams-headline"
              >
                Teams
              </h3>
              <div
                className="mt-1 space-y-1"
                role="group"
                aria-labelledby="desktop-teams-headline"
              >
                {props.teams.map((team) => (
                  <a
                    key={team.name}
                    href={team.href}
                    className="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-cyan-500 hover:bg-cyan-600 hover:text-cyan-300"
                  >
                    <span
                      className={join(
                        [team.bgColorClass, "w-2.5 h-2.5 mr-4 rounded-full"],
                        " "
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{team.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
