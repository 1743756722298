import { Fragment, useRef, useState, ChangeEvent } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Search, User } from "react-feather";
import { useConnectRepo, useSearchRepos } from "../../api/services/Source";
import { Spinner } from "../../components/api/Spinner";
import { useCurrentSrc } from "../../state";

const DEBOUNCE_DELAY = 300;

interface AdProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function AddDialog(props: AdProps) {
  const [srcId] = useCurrentSrc();
  const [query, setQuery] = useState("");
  const [debounceId, setDebounceId] = useState<number | null>(null);
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const cancelButtonRef = useRef(null);
  const { data: repos, isFetching } = useSearchRepos(srcId, debouncedQuery);
  const connectRepo = useConnectRepo(srcId);

  const debounceChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setQuery(evt.target.value);

    if (debounceId !== null) {
      clearTimeout(debounceId!!);
    }

    let timeoutId = window.setTimeout(() => {
      setDebouncedQuery(evt.target.value);
    }, DEBOUNCE_DELAY);
    setDebounceId(timeoutId);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg">
                <div>
                  <div>
                    <div className="relative border-b flex items-center pr-2">
                      <Search
                        className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <input
                        className="h-12 flex-1 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 ring-0 outline-0"
                        placeholder="Search..."
                        onChange={debounceChange}
                      />
                      {isFetching ? <Spinner size="h-5 w-5" /> : <></>}
                    </div>

                    {repos && repos.length > 0 && (
                      <ul className="py-1 divide-y divide-gray-100">
                        {repos.map((repo) => (
                          <li
                            key={repo.id}
                            className="flex p-2 px-4 min-h-[57px] hover:bg-gray-200"
                          >
                            <div className="flex items-center space-x-4 w-full">
                              <div className="flex-shrink-0">
                                {repo.avatar ? (
                                  <img
                                    className="h-8 w-8 rounded-full"
                                    src={repo.avatar}
                                    alt=""
                                  />
                                ) : (
                                  <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                    <span className="text-sm font-medium leading-none text-white">
                                      {repo.name.substring(0, 2)}
                                    </span>
                                  </span>
                                )}
                              </div>
                              <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-gray-900">
                                  {repo.owner}/{repo.name}
                                </p>
                                <p className="truncate text-sm text-gray-500">
                                  {repo.description}
                                </p>
                              </div>
                              <div>
                                <button
                                  className="inline-flex items-center rounded-md border border-gray-200 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
                                  onClick={() => {
                                    connectRepo.mutate(
                                      `${repo.owner}/${repo.name}`,
                                      {
                                        onSuccess: () => {
                                          props.setOpen(false);
                                        },
                                      }
                                    );
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}

                    {query !== "" && repos && repos.length === 0 && (
                      <div className="py-14 px-4 text-center sm:px-14">
                        <User
                          className="mx-auto h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                        <p className="mt-4 text-sm text-gray-900">
                          No people found using that search term.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
