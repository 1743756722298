import { join } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Calendar,
  CheckCircle,
  ChevronDown,
  Clock,
  HelpCircle,
  PauseCircle,
  RotateCw,
  XCircle,
} from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import {
  Job,
  statusTextColor,
  useJob,
  useLog,
  useRerunJob,
} from "../../api/services/Job";
import { useRepoDetail } from "../../api/services/Repo";
import { Spinner } from "../../components/api/Spinner";
import { Button } from "../../components/button/Button";
import { Commit, Ref } from "../../components/repo";
import { Duration } from "../../components/utils/Duration";
import { Status } from "../../domain/state";
import { useRepoId } from "../../state";
import { displayTime } from "../../utils";
import { useHeader } from "../header/Header";
import { Stages } from "./Logs";

export function JobDetail() {
  const repoId = useRepoId();
  const { jobId } = useParams();

  const { data: repo } = useRepoDetail(repoId);
  const { data: job } = useJob(repoId, jobId);
  const { data: state } = useLog(repoId, jobId, job?.status === Status.Running);
  const rerunJob = useRerunJob();
  const navigate = useNavigate();

  // update header
  const setHeader = useHeader();
  useEffect(() => {
    if (repo) {
      setHeader([
        { name: "Repository", href: "/" },
        { name: `${repo.owner}/${repo.name}`, href: `/repo/${repoId}/job` },
        { name: `#${jobId}` },
      ]);
    }
  }, [repoId, jobId, repo, setHeader]);

  // autoscroll
  const [autoScroll, setAutoScroll] = useState<boolean>(true);

  const logRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = useCallback(() => {
    logRef.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (state?.jobState.status === Status.Running && autoScroll) {
      scrollToBottom();
    }
  }, [state?.jobState, autoScroll, scrollToBottom]);

  // scroll handler
  const scrollHandler = () => {
    const bottomMargin = 300;
    if (
      window.innerHeight + window.pageYOffset >=
      document.documentElement.scrollHeight - bottomMargin
    ) {
      setAutoScroll(true);
    } else {
      setAutoScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <>
      <div className="flex border-b border-gray-200 p-5 bg-white">
        <div className="job-status">{statusIcon(state?.jobState.status)}</div>
        <span className="flex flex-1 flex-col ml-4">
          <span className="text-2xl font-semibold">
            {repo?.owner}/{repo?.name} #{jobId}
          </span>
          <span className="mt-2 flex">
            <CommitInfo
              refs={job?.ref ?? ""}
              id={job?.commits[0].id ?? ""}
              url={job?.commits[0].url ?? ""}
            />
            <Datetime className="ml-10" time={job?.endTime} />
            <JobDuration className="ml-10" job={job} />
          </span>
        </span>
        <span className="flex items-end">
          <Button
            text="Rerun"
            icon={RotateCw}
            onClick={() => {
              if (repoId !== undefined && jobId !== undefined) {
                rerunJob.mutate(
                  { repoId, jobId },
                  {
                    onSuccess: (resp, jid) => {
                      navigate(`/repo/${jid.repoId}/job/${resp.data.data}`);
                    },
                  }
                );
              }
            }}
          />
        </span>
      </div>
      <div className="m-3 flex gap-3 items-start">
        <div
          className="inline-block bg-white rounded-md p-8 border border-gray-200 w-[calc(100%-20rem)]"
          ref={logRef}
        >
          {state?.jobState.pipeStates?.map((pipe, pi) =>
            pipe.taskStates?.map((task, ti) => (
              <div key={ti}>
                <div className="border-b border-gray-200 pb-3 sm:flex sm:items-center sm:justify-between">
                  <h3 className="text-xl font-medium leading-6 text-gray-900">
                    {pi > 0 ? "Task " + ti : "Prepare"}:{" "}
                    {state.getTask(pi, ti)?.name}
                  </h3>
                  <div className="mt-3 sm:mt-0 sm:ml-4 flex items-center">
                    <span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                      {state.getTask(pi, ti)?.type}
                    </span>
                    <ChevronDown size={16} className="text-gray-400 ml-4" />
                  </div>
                </div>
                <div className="pt-4 mb-8 flex-1">
                  <Stages
                    steps={task.stageStates.map((stage, si) => {
                      return {
                        name: "Stage " + si,
                        description:
                          stage.logs && stage.logs?.length > 0
                            ? displayTime(stage.logs[0].time)
                            : "-",
                        href: "#",
                        status: stage.status,
                        code: stage.logs?.map((log) => log.content).join("\n"),
                      };
                    })}
                    last={pipe.taskStates && ti === pipe.taskStates?.length - 1}
                    status={pipe?.status}
                  />
                </div>
              </div>
            ))
          )}
        </div>
        <div className="bg-white rounded-md p-8 border border-gray-200 w-80">
          {/*<Progress
             steps={
             log?.content.tasks?.map((task) => {
             return {
name: task.name,
description:
task.stages.length > 0 &&
task.stages[0].logs &&
task.stages[0].logs?.length > 0
  ? displayTime(task.stages[0].logs[0].time)
  : "-",
href: "#",
status: "complete",
};
}) ?? []
}
/>*/}
        </div>
      </div>
    </>
  );
}

interface Props {
  className?: string;
}

interface ciProps extends Props {
  id: string;
  url: string;
  refs: string;
}

function CommitInfo(props: ciProps) {
  return (
    <span className={join(["commit text-gray-500 flex", props.className], " ")}>
      <Ref refs={props.refs} className="text-sm" />
      <span className="ml-1">
        (
        <Commit
          id={props.id}
          url={props.url}
          className="text-blue-400 text-sm"
        />
        )
      </span>
    </span>
  );
}

interface dtProps extends Props {
  time: DateTime | undefined;
}

function Datetime(props: dtProps) {
  return (
    <span
      className={join(
        ["datetime text-gray-500 flex items-center", props.className],
        " "
      )}
    >
      <Calendar size={16} />
      <span className="ml-1 text-sm">{props.time?.toRelative()}</span>
    </span>
  );
}

interface dProps extends Props {
  job?: Job;
}

function JobDuration(props: dProps) {
  return (
    <span
      className={join(
        ["datetime text-gray-500 flex items-center", props.className],
        " "
      )}
    >
      <Clock size={16} />
      <span className="ml-1 text-sm">
        {props.job ? (
          <Duration
            start={props.job?.startTime}
            end={props.job.endTime}
            running={props.job.status === Status.Running}
          />
        ) : (
          "-"
        )}
      </span>
    </span>
  );
}

function statusIcon(status?: Status) {
  const iconClass = `${statusTextColor(status ?? Status.Unknown)} w-16 h-16`;
  switch (status) {
    case Status.Pending:
      return <PauseCircle className={iconClass} />;
    case Status.Running:
      return <Spinner size="h-16 w-16" />;
    case Status.Success:
      return <CheckCircle className={iconClass} />;
    case Status.Failed:
      return <XCircle className={iconClass} />;
    default:
      return <HelpCircle className={iconClass} />;
  }
}
