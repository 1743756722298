import { join } from "lodash";
import { MouseEventHandler } from "react";
import { Icon } from "react-feather";

export interface Props {
  text: string;
  className?: string;
  icon?: Icon;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export function Button(props: Props) {
  return (
    <button
      type="button"
      className={join(
        [
          "inline-flex items-center rounded-md border border-gray-200 px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
          props.className,
        ],
        " "
      )}
      onClick={props.onClick}
    >
      {props.icon ? <props.icon className="-ml-0.5 mr-2 h-4 w-4" /> : <></>}
      {props.text}
    </button>
  );
}
